var cpx_cart = null;

function init_cpx_cart() {
    $body = $('body');
    $cart = $('.cpx-cart');
    $cart_backdrop = $('.cpx-cart-backdrop');
    $update_button = $cart.find('.cpx-cart__update-button');

    // Cart exists
    if ($cart.length < 1) {
        return false;
    }

    cpx_cart = {
        el: $cart,
        is_open: false,
        open: function () {
            $body.addClass('cpx-cart-open'); 
            $cart_backdrop.fadeIn(300);
            this.is_open = true;

            // if(cpx_menu) {
            //     cpx_menu.close();
            // }
            var $hamburger = $('.js-hamburger');

            $body.removeClass('mobile-menu-open');
            $hamburger.removeClass('is-active');
        },
        close: function () {
            $body.removeClass('cpx-cart-open');
            $cart_backdrop.fadeOut(300);
            this.is_open = false;
        },
        toggle: function () {
            if (this.is_open) {
                this.close();
            }
            else {
                this.open();
            }
        },
        update: function () {
            var $cart_items_form = $cart.find('.js-cart-items');
            if ($cart_items_form.length) {
                // Collect cart items
                var cart_items = [];
                var $input_fields = $cart_items_form.find('input.cpx-cart__product__qty');
                $input_fields.each(function () {
                    var $this = $(this);
                    var input_cart_key = '';
                    var input_cart_qty = '';

                    if ($this.attr('data-cpx-cart-key')) {
                        input_cart_key = $this.attr('data-cpx-cart-key');
                    }
                    if ($this.val()) {
                        input_cart_qty = $this.val();
                    }

                    if (input_cart_key && input_cart_qty) {
                        cart_items.push({
                            cart_key: input_cart_key,
                            cart_qty: input_cart_qty
                        });
                    }
                });

                // START POST
                $.ajax({
                    type: "POST",
                    url: cpx_ajax_url,
                    data: {
                        action: 'update_cpx_cart_callback',
                        cart_items: cart_items
                    },
                    success: function (response) {
                        try {
                            response = JSON.parse(response);
                            window.location = window.location.href.split("#")[0];
                        }
                        catch (e) {
                            console.error(e);
                        }
                    }
                });
                // END POST
            }
        }
    };

    $('.js-open-cpx-cart').on('click', function () { cpx_cart.open(); });
    $('.js-close-cpx-cart').on('click', function () { cpx_cart.close(); });
    $('.js-toggle-cpx-cart').on('click', function () { cpx_cart.toggle(); });
    $cart.find('.cpx-cart__product__qty').on('change', function () {
        $update_button.fadeIn();
        $cart.addClass('update-button-visible');
    });
    $update_button.on('click', function () { cpx_cart.update(); });

    $(document).keyup(function (e) {
        if (e.key === "Escape") {
            cpx_cart.close();
        }
    });

    // Keep cart open after deleting something
    var query_string = window.location.search;
    var url_params = new URLSearchParams(query_string);
    if (url_params.get('removed_item') && url_params.get('removed_item') == 1) {
        cpx_cart.open();
    }
}

$(document).ready(function () {
    init_cpx_cart();
});