function init_header() {
    // Mobile hambuger
    $('.js-toggle-mobile-menu').on('click', function() {
      var $body = $('body');
      var $hamburger = $('.js-hamburger');
  
      $body.toggleClass('mobile-menu-open');
      $hamburger.toggleClass('is-active');
    });
}

$(document).ready(function() {
  init_header();
});
   