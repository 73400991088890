var myLazyLoad = null;
jQuery(document).ready(function ($) {
    new WOW({
        animateClass: "animate__animated" 
    }).init();

    myLazyLoad = new LazyLoad({
        cancel_on_exit: false
    });
    if (myLazyLoad) {
        myLazyLoad.update();
    }
});
