function init_faq_items() {
    var $faq_items = $('.js-faq-item');

    if($faq_items.length) {
        $faq_items.each(function() {
            var $faq_item = $(this);
            var $top_row = $faq_item.find('.faq-items__item__top-row');
            var $bottom_row = $faq_item.find('.faq-items__item__bottom-row');

            $top_row.on('click', function() {
                console.log('clicked');
                $faq_item.toggleClass('active');
            });
        });
    }
}

$(document).ready(function() {
    init_faq_items();
});